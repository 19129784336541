<template>
  <div class="container-box">
    <div class="main-box">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item @click="$router.go(-1)"><router-link to="/">首页</router-link></a-breadcrumb-item>
        <a-breadcrumb-item style="color:#15B7DD;">常见问答</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="content-box">
        <div class="title-box">
          <div class="line"></div>常见问答
        </div>
        <div class="tab-box">
          <!-- <div v-for="(item,index) in tabList" :key="index" class="tab-list" @click="onTab(item)">
            <div class="tab-title" :class="{'tab-active' : item.isClick}" >{{item.categoryName}}</div>
          </div> -->
          <div class="tab-left"></div>
          <div class="swiper" ref="tabWidth">
            <template>
              <swiper ref="mySwipert" :options="swiperOptiont" v-if="tabList.length > 0">
                <swiper-slide v-for="(item, index) in tabList" :key="index" >
                  <div class="industryitem">
                    <div class="tab-list" @click="onTab(item)">
                      <div class="tab-title" :class="{'tab-active' : item.isClick}" >{{item.categoryName}}</div>
                    </div>
                  </div>
                </swiper-slide>
                <div 
                  v-show="showTabWidth"
                  class="
                    swiper-button-next swiper_btn swiper-button-white
                    tab-next
                  "
                  slot="button-next"
                  @mouseover="changeMaskTab(1)"
                  @mouseout="changeMaskTab(2)"
                >
                  <img
                    v-show="swiperRightTab"
                    src="@/assets/image/index/icon_next.png"
                    alt=""
                  />
                  <img
                    v-show="!swiperRightTab"
                    src="@/assets/image/index/icon_next1.png"
                    alt=""
                  />
                </div>
              </swiper>
            </template>
          </div>
        </div>

        <div class="brief-box">
          <div class="brief-left">{{info}}</div>
          <div class="brief-right">
            <img class="brief-pic" src="@/assets/image/index/pic_brief.png" alt="" />
            <div class="brief-num">共{{num}}个问答</div>
          </div>
        </div>

        <div class="list-box">
          <div v-for="(item,index) in answerList" :key="index" class="list" :class="item.isOpen ? '' : 'list-close'">
            <div class="list-title">
              <img class="list-img" src="@/assets/image/index/icon_wechat.png" alt="" />{{item.title}}
              <!-- {{fun_divHeight('hideText' + item.qaId)}} -->
            </div>
            <div  class="hideText" :ref="'hideText' + item.qaId" :id="'hideText' + item.qaId">{{item.content}}</div>
            
            <div class="wrapper">
              <input :id="'exp' + item.qaId" class="exp" type="checkbox" />
              <div class="text">
                <label v-show="answerHeightArry[index] > 120" class="btn" :id="'btn' + item.qaId" :for="'exp' + item.qaId"></label>
                {{item.content}}
              </div>
            </div>
            <!-- <div class="list-content " :class="item.isOpen ? '' : 'mallHidden'" v-html="item.content">
            </div> -->
            <!-- <div v-if="item.content.length > 243" class="list-more" @click="item.isOpen = !item.isOpen">{{ item.isOpen ? "收起" : "阅读全文" }}</div> -->
          </div>
          <no-message :msg="'暂无数据'"  v-if="!answerList.length && !loading"/>
          <a-spin class="spin" size='large' v-show="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      answerHeightArry: [],
      info: '',
      num: 0,
      typeID: '',
      tabList: [],
      answerList: [],
      loading: false,

      tabShow: '',
      showTabWidth: false,
      swiperRightTab: false,
      swiperOptiont: {
        slidesPerView: "auto",
        spaceBetween: 20,
        navigation: {
          nextEl: ".tab-next",
          disabledClass: "my-button-disabled",
        },
        on: {
          click: () => {
            this.onTab();
          },
        },
      },
    }
  },
  // 事件处理器
  methods: {
    font(e){
      e = e.replace(/&nbsp;/g,'')
      return e.replace(/\<[^>]+>/g,'')
    },
    onTab(){
      if(this.$refs.mySwipert.$swiper){
        let realIndex = this.$refs.mySwipert.$swiper.clickedIndex
        if (!isNaN(realIndex)) {
          this.tabList.forEach((item,index) => {
            if(index == realIndex){
              item.isClick = true;
              this.info = item.info;
              this.typeID = item.answerId;
            }else{
              item.isClick = false;
            }
          });
        }
      }
    },

    changeMaskTab(e) {
      if (e == 1) {
        this.swiperRightTab = true;
      } else if (e == 2) {
        this.swiperRightTab = false;
      }
    },
    
    getQaA(){
      this.loading = true;
      this.$ajax({
        url: '/hxclass-pc/information/answer/category',
        params:{
          page: 1,
          size: 999,
        }
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tabList = res.data.records.map((item,index) =>{
            if(index == 0){
              this.info = item.info;
              this.typeID = item.answerId;
              return {...item, isClick: true};
            }else{
              return {...item, isClick: false};
            }
          })

          let widthTotal = 0
          setTimeout(()=>{
            if(this.$refs.infoSwipert){
              this.$refs.mySwipert.$children.forEach(element => {
                widthTotal = widthTotal + element.$el.clientWidth + 20
              });
              this.tabShow = widthTotal
              if (this.tabShow > this.$refs.tabWidth.offsetWidth) {
                this.showTabWidth = true;
              }
            }
          },500)
        }
      })
    },

    getQaList(typeID){
      this.loading = true;
      this.$ajax({
        url: '/hxclass-pc/information/answer',
        params:{
          qaTypeId: typeID?this.typeID:typeID,
          page: 1,
          size: 999,
        }
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.num = res.data.total;
          this.answerList = res.data.records.map(item =>{
              return {
                ...item, 
                content: this.font(item.content),
                isOpen: false, 
              };
          })

          // 将渲染的回答高度放到一个数组,用于是否显示展示收起按钮
          this.answerHeightArry = []
          this.$nextTick(()=>{
            this.answerList.forEach(element => {
              const idName = 'hideText' + element.qaId
              const clientHeight = this.$refs[idName][0].clientHeight
              this.answerHeightArry.push(clientHeight)
            });
          })
          console.log(this.answerHeightArry)
        } else {
          this.answerList = []
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getQaA();
   },
  // 生命周期-实例挂载后调用
  mounted () {
    this.$emit('setIndex', false);
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    typeID(newval){
      this.getQaList(newval);
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.noMessage{
  margin-top: 80px;
}
.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-box{
  .mallHidden{
    -webkit-line-clamp: 4;
  }
  .main-box{
    padding-top: 22px;
    padding-bottom: 60px;
    .content-box{
      margin-top: 22px;
      width: 100%;
      min-height: 720px;
      background-color: #FFF;
      .title-box{
        padding: 40px 40px 0;
        font-size: 24px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #15B7DD;
        line-height: 28px;
        .line{
          display: inline-block;
          margin-right: 8px;
          width: 4px;
          height: 19px;
          background: #15B7DD;
          border-radius: 2px 2px 2px 2px;
          vertical-align: middle;
        }
      }

      .tab-box{
        position: relative;
        margin-top: 24px;
        padding: 0 0 0 20px;
        height: 38px;
        overflow: hidden;
        .tab-left {
          position: absolute;
          left: 0;
          height: 100%;
          width: 20px;
          background: rgba(21, 183, 221, 0.1);
        }
        .tab-list{
          margin: 0 20px;
          .tab-title{
            // padding:0 5px;
            cursor: pointer;
            height: 100%;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #666666;
            line-height: 38px;
            text-align: center;
          }
          .tab-active{
            color: #FFF;
            font-weight: 500;
            background: #15B7DD;
          }
        }
        ::v-deep.ant-carousel {
        .slick-slide {
          text-align: center;
          height: 447px;
          line-height: 160px;
          background: #364d79;
          overflow: hidden;
        }
        .slick-slide h3 {
          color: #fff;
        }
      }
      ::v-deep.dotsClass {
        li {
          margin: 0 10px;
          button {
            width: 24px !important;
          }
        }
      }
      // 轮播样式
      .industryitem {
        position: relative;
        z-index: 3;
      }
      // /deep/.swiper-container {
      //   overflow: inherit;
      // }
      /deep/.swiper-button-next:after,
      .swiper-container-rtl .swiper-button-prev:after {
        content: "";
      }
      ::v-deep.swiper-button-next {
        position: absolute;
        right: -2px;
        top: 22px;
        z-index: 5;
        height: 38px;
        width: 24px;
        background: #ebf6fb;
        img {
          position: relative;
          width: 7px;
          height: 14px;
        }
      }
      /deep/.swiper-slide {
        width: auto;
      }
      /deep/.tab-active {
        padding: 0 32px;
      }
      .swiper {
        position: relative;
        width: auto;
        display: inline-block;
        background: rgba(21, 183, 221, 0.1);
        max-width: 100%;
      }
      }

      .brief-box{
        display: flex;
        justify-content: space-between;
        margin: 28px 58px 20px;
        .brief-left{
          width: 1032px;
          height: 75px;
          font-size: 15px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #000000;
          line-height: 24px;
        }
        .brief-right{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 207px;
          height: 75px;
          border: 1px dashed #15B7DD;
          .brief-pic{
            margin-right: 12px;
            width: 43px;
            height: 43px;
          }
          .brief-num{
            font-size: 20px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: @text-color;
            line-height: 23px;
          }
        }
      }

      .list-box{
        position: relative;
        padding: 0 30px 80px;
        min-height: 300px;
        .list{
          margin-top: 20px;
          padding: 20px 20px;
          width: 1340px;
          background: #FFFFFF;
          border-bottom: 1px solid rgba(196, 196, 196, 0.5);
          
          .list-title{
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: @text-color;
            line-height: 36px;
            .list-img{
              margin-right: 8px;
              width: 24px;
              height: 21px;
            }
          }
          .list-content{
            padding: 20px 30px 4px 52px;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: @text-color;
            line-height: 30px;
          }
          .list-more{
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            margin-top: 6px;
            padding-right: 30px;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: @border-color;
            line-height: 30px;
          }
          // /deep/ .btn::before {
          //   content: "阅读全文" !important;
          // }
          // /deep/ .exp:checked + .text .btn::before {
          //   content: "收起" !important;
          // }
        }
        .list:hover{
          border-bottom: none;
          box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
        }
        .hideText{
          position: absolute;
          z-index: -10;
          opacity: 0;
          padding: 15px;
          line-height: 30px;
          font-size: 20px;
        }
        .wrapper {
          display: flex;
          width: 100%;
          overflow: hidden;
          border-radius: 8px;
          padding: 15px;
        }
        .text {
          font-family: PingFangMedium;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: justify;
          display: -webkit-box;  //将对象作为弹性伸缩盒子模型显示
          -webkit-line-clamp: 3; //下多行文字溢出点点点... 
          -webkit-box-orient: vertical; //设置或检索伸缩盒对象的子元素的排列方式
          position: relative;
        }
        .text::before {
          content: "";
          height: calc(100% - 24px);
          float: right;
        }
        .btn {
          float: right;
          clear: both;
          margin-left: 5px;
          font-size: 18px;
          border-radius: 4px;
          font-weight: 400;
          color: #15B7DD;
          line-height: 19px;
          cursor: pointer;
        }
        .exp {
          display: none;
        }
        .exp:checked + .text {
          -webkit-line-clamp: 999;
        }
        .btn::before {
          content: "阅读全文";
        }
        .exp:checked + .text .btn::before {
          content: "收起";
        }
      }
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .container-box {
    .main-box {
      .content-box {
        .brief-box {
          .brief-right{
            width: 187px;
            height: 55px;
            .brief-pic{
              width: 30px;
              height: 30px;
            }
            .brief-num{
              font-size: 16px;
            }
          }
        }
        .list-box {
          // .hideText{
          //   font-size: 16px;
          // }
          // .wrapper{
            // padding: 5px 0;
          // }
          // .text{
          //   font-size: 16px;
          // }
          .list{
            width: 100%;
            // .list-title{
            //   font-size: 20px;
            // }
          }
        }
      }
    }
  }
}
</style>
